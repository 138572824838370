@import url('https://fonts.googleapis.com/css?family=Monoton');

:root {
    --active-color: #e16329;
    --not-active: hsl(0 0 0);
    --hover-color: #f49f9f;
}

body {
    margin: 0;
    box-sizing: border-box;
}

#primary-navigation {
    padding: 0px 0px 0px 30px;
    margin-top: 0.4em;
}

#primary-navigation > a {
    list-style: none;
    margin-right: 0.5em;
    text-decoration-line: none;
    padding: 0.4em;
    transition-duration: 0.5s;
    top: 0;
}

#primary-navigation > a:hover {
    color: var(--hover-color);
    background-color: #fff;
    border-radius: 4px;
    transition: all 250ms ease-in-out;
}

.sticky-header {
    font-family: 'Monoton', cursive;
    font-size: 20px;;
    position: fixed;
    color: transparent;
    top: 0;
    /* background-color: rgb(234, 131, 47); */
    box-shadow: 1px 1px 8px 3px;
    width: 100vw;
    height: 10vh;
    margin-bottom: 3rem;
    margin-top: 0px;
    min-height: 5em;
    z-index: 10;
    display:flex;
    place-content: center;
    align-items: center;
}

.sticky-header img {
    /* margin-top: 0.20rem; */
    margin-left: 0.50rem;
    margin-bottom: 0.3rem;
    justify-content: center;
}

.sticky-header::after {
    content: '';
    position: absolute;
    background-color: rgb(234, 131, 47);
    inset: 0;
    z-index: -1;
    opacity: 0.4;
}

.sticky-header ul {
    margin: 0px 0px 20px 0px;
    padding: 0px 0px 0px 40px;
    overflow: cover;
    display: flex;
    align-content: center;
}

.isActiveColor {
    color: var(--active-color);
}

.notActiveColor {
    color: var(--not-active);
}
