.service-wrapper {
    /* margin: 0; */
    display: flex;
    position: relative;
    gap: 0.8rem;
    margin-inline: 0rem;
    padding: 0;
    margin-top: 0;
    /* margin-bottom: 1rem; */
    border-block: 1px solid var(--site-color);
}

.service-wrapper > * {
    flex: 1;
}

.service-wrapper::after {
    content: '';
    inset: 0;
    opacity: 0.5;
    position: absolute;
    /* background-color: rgb(234, 131, 47); */
    background-color: var(--site-color);
    /* margin-bottom: 1rem; */
}

.service-object {
    position: relative;
    display: flex;
    flex-direction: column;
    /* place-content: center; */
    align-items: center;
    /* justify-content: center; */
    /* align-content: center; */
    margin-inline: 0.2rem;
    box-shadow: .5rem 1.25rem 3rem -2rem hsl(var(--clr-secondary-400) / 0.45);
}

.service-object > object {
    /* fill: var(--site-color); */
    margin-top: 1rem;
    
}

.my-svg path {
    /* fill: white; */
}

.service-object::after {
    content: "";
    inset: 0;
    position: absolute;
    background-color: var(--site-color);
    opacity: 0.2;
}

.service-wrapper > * {
    /* background-color: var(--site-color); */
    margin-block: 1rem;
}

.service-wrapper p {
    padding-inline: 1rem;
}