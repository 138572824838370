.chop-container {
	margin: 0;
	display: flex;
	justify-content: center;
	align-items: center;
	max-width: 100%;
	overflow: hidden;
	/* border: solid 3px blue; */
}

.chop-container > img {
	/*width: 350px;*/
	/*height: 200px;*/
	object-fit:cover;
	border-radius: 10px;
    box-shadow: 1.5rem 2.75rem 3rem -2rem hsl(var(--clr-secondary-400) / 0.45);
	margin-inline: 7px;
	margin-bottom: 2rem;
	margin-top: 1rem;

	max-width: 100%;
	overflow: hidden;
}
  
.chop-container img {
	height: 8em;
	width: 10em;
	overflow: hidden;
	/* margin-bottom: 2.0rem; */
}
