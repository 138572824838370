.about {
    padding: 0;
    /* margin-top: 0; */
    margin-inline: auto;
    /* width: min(90%, 60rem); */
    line-height: 1.7rem;
    position: relative;
    /* border: 2px solid red; */
    isolation: isolate;
    place-content: center;
    /* text-align: center; */
    border-radius: 3px;
    margin-bottom: 1rem;
    margin-top: 1rem;
    box-shadow: 0.5rem 0.75rem 6rem -2rem hsl(var(--clr-secondary-400) / 0.45);
    /* border: 2px solid red; */
    border-block: 2px solid var(--site-color);
}

.about > p {
    margin-inline: 1rem;
    font-weight: 450;
}

.about::after {
    content: '';
    inset: 0;
    background-color: rgb(234, 131, 47);
    /* background-color: var(--site-color); */
    position:absolute;
    /* z-index: -1; */
    opacity: 0.2;
    box-shadow: 1.5rem 2.75rem 3rem -2rem hsl(var(--clr-secondary-400) / 0.45);
}

@media (min-width: 620px) {
    .about {
        width: min(95%, 630px);
    }
}