

*,
*::before,
*::after {
    box-sizing: border-box;
}

.grid-container {
    display: grid;
    gap: 1.5rem;
    grid-auto-columns: 1fr;
    grid-template-areas: 
        'one'
        'two'
        'three'
        'four'
        'five';

    padding-block: 2rem;
    width: min(95%, 70rem);
    margin-inline: auto;
    margin-top: 6rem;
}

@media (min-width: 40em) {
    .grid-container {
        grid-template-areas: 
            'one one two five'
            'three four four five';
    }
    /* .grid-container {
        grid-template-columns: repeat(4, 1fr);
    }

    .grid-object:last-child {
        grid-column-start: 4;
        grid-row: 1 / span 2;
    }

    .grid-col-span-2 {
        grid-column: span 2;
    } */
}

.grid-object:nth-child(1) {
    grid-area: one;
}

.grid-object:nth-child(2) {
    grid-area: two;
}

.grid-object:nth-child(3) {
    grid-area: three;
}

.grid-object:nth-child(4) {
    grid-area: four;
}

.grid-object:nth-child(5) {
    grid-area: five;
}


.grid-object {
    padding: 2rem;
    border-radius: 0.5rem;
    box-shadow: 1.5rem 2.75rem 3rem -2rem hsl(var(--clr-secondary-400) / 0.45);
}

/* .grid-object:nth-child(3) {
    background-color: red !important;
} */


/* Background color classes */

.blue {
    background-color: blue;
}

.green {
    background-color: greenyellow;
}

.gold {
    background-color: gold;
}

.purple {
    background-color: purple;
}

.last-color {
    background-color: blanchedalmond;
}