.bordered-container {
    
    /* border: 2px solid green; */
    margin-inline: auto;
    box-shadow: -0.0rem 0.75rem 3rem -2rem hsl(var(--clr-secondary-400) / 0.45);
    
}

@media (min-width: 620px) {
    .bordered-container {
        width: min(95%, 750px);
        /* border-inline: 1px solid hsla(19, 75%, 52%, 0.25); */
        min-height: 120vh;
    }
}