:root {
  --clr-primary-400: 263 55% 52%;
  --clr-secondary-400: 217 19% 35%;
  --clr-secondary-500: 219 29% 14%;
  --clr-neutral-100: 0 0% 100%;
  --clr-neutral-200: 210 46% 95%;
  --clr-neutral-300: 0 0% 81%;
  --active-color: hwb(19 16% 12%);
  --not-active: hsl(0 0 0);
  --hover-color: #f49f9f;
  --site-color: hsla(19, 75%, 52%, 0.5);
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

html {
  margin: 0px;
}

.breaker {
  margin: 0;
  margin-top: 0;
  padding: 0;
  /* margin-bottom: 1rem; */
  border-top: 1px solid var(--site-color);
}

/* Media Query for Mobile Devices */
@media (max-width: 480px) {
  body {
  }
}

/* Media Query for low resolution  Tablets, Ipads */
@media (min-width: 481px) and (max-width: 767px) {
  body {
  }
}

/* Media Query for Tablets Ipads portrait mode */
@media (min-width: 768px) and (max-width: 1024px) {
  body {
  }
}

/* Media Query for Laptops and Desktops */
@media (min-width: 1025px) and (max-width: 1280px) {
  body {
  }
}

/* Media Query for Large screens */
@media (min-width: 1281px) {
  body {
  }
}
