*,
*::before,
*::after {
    box-sizing: border-box;
}

.hamburger-container {
    margin-inline: auto;
    margin-top: 6rem;
    padding: 3rem;
    width: min(95%, 70rem);
    display: flex;
    gap: clamp(2rem, 8vw, 4rem);
}

button {
    background: transparent;
    border: 10px solid var(--button-color, #333);
    border-radius: 1rem;
}

.button-one {
    --button-color: #333;
}

.button-one:hover :is(.top, .bottom) {
    y: 45;
}

.button-one:hover .top {
    rotate: 45deg;
}

.button-one:hover .bottom {
    rotate: -45deg;
}

.button-one:hover .middle {
    opacity: 0;
}

.button-one .line {
    transition:
        y 300ms ease-in 300ms,
        rotate 300ms ease-in,
        opacity 0ms ease-in 300ms;
    transform-origin: center;
}

.button-one:hover .line {
    transition:
        y 300ms ease-in,
        rotate 300ms ease-in 300ms,
        opacity 0ms ease-in 300ms;
    transform-origin: center;
}

/* Button two */

.button-two {
    --button-color: green;
}