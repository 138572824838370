.hero {
  background-image: url("../../public/pictures/hero_cropped_2.jpg");
  background-repeat:no-repeat;
  background-size: cover;
  background-position: center;
  /* top: 0; */
  /* inset: 0; */
  /* margin-left: auto;
  margin-right: auto; */
  margin-inline: auto;
  min-height: 375px;
  position: relative;
  border-radius: 0.15rem;
  box-shadow: 1.5rem 2.75rem 3rem -2rem hsl(var(--clr-secondary-400) / 0.45);
  margin-top: 6.5rem;
  place-content: center;
  align-items: center;
  /* display: grid; */
  /* border: 1px red purple; */
  /* box-shadow: 8px 8px 15px #191919; */
  isolation: isolate;
  margin-bottom: 1.5rem;
}

.hero-text::after {
  content: '';
  position: absolute;
  background-color: pink;
  inset: 0;
  z-index: -1;
  opacity: 0.3;
}

.hero-border {
  max-width: 100vw;
  height: min(50vh, 70rem);
  position: absolute; 
  background-color: red;
  z-index: -1;
  clip-path: polygon(100% 0%, 75% 50%, 100% 100%, 25% 100%, 0% 50%, 25% 0%);
}

.hero-text {
  margin:0;
  position: absolute;
  color: #ffffff;
  text-align: center;
  font-family: 'Monoton', cursive;
  font-size: 26px;
  font-weight: 100;
  bottom: 0px;
  width: 100%;
  /* inset: 0; */
  /* backdrop-filter: blur(2px) saturate(60%); */
}

.hero > p {
  text-align: center;
}

/* Media Query for Mobile Devices */
@media (max-width: 630px) {
    body {
        background-color: #ffffff;
    }


    .hero {
       background-size: cover;
       min-height: 32vh;
    }
    .hero-text {
      font-size: 16px;
    }
  }

  @media (min-width: 720px) {
    .hero {
        width: min(95%, 630px);
    }
}

