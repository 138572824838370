*,
*::before,
*::after {
    box-sizing: border-box;
}

.wrapper {
    height: 100vh;
    /* margin-top: 300px; */
    background: #998235;
    display: flex;
    align-items: center;
    justify-content: center;
}

#p {
    width: 360px;
    height: 200px;
    background: #191919;
    border-radius: 10px;
    position: relative;
}

.white-keys {
    display: flex;
    height: 100%;
    gap: 5px;
}

.white-keys > * {
    background: #ffffff;
    border-radius: 5px;
    height: 70%;
    width: 100px;
    margin-top: auto;
}

.black-keys {
    position: absolute;
    inset: 0 10px;
    display: flex;
    inset: 0;
    justify-content: space-evenly;
    height: 100%;
}

.black-keys > * {
    background: #191919;
    width: 28px;
    height: 130px
}

.t-key {
    background: transparent;
}